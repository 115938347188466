import React from "react";

import Article from "./article";
import { JobCard, JobCardList } from "./job-card";

const MemberJobs = ({ member }) => {
  let jobList = null;
  let crafterList = null;
  let gathererList = null;
  let contentsList = null;

  jobList = member.jobs.battle.map((job, index) => {
    return <JobCard key={index} job={job} />;
  });

  crafterList = member.jobs.crafter.map((job, index) => {
    return <JobCard key={index} job={job} />;
  });

  gathererList = member.jobs.gatherer.map((job, index) => {
    return <JobCard key={index} job={job} />;
  });

  contentsList = member.contents.levels.map((job, index) => {
    return <JobCard key={index} job={job} />;
  });

  return (
    <Article>
      <h2>Jobs</h2>
      <h3>Battle</h3>
      <JobCardList>
        {jobList}
      </JobCardList>

      <h3>Crafter</h3>
      <JobCardList>
        {crafterList}
      </JobCardList>

      <h3>Gatherer</h3>
      <JobCardList>
        {gathererList}
      </JobCardList>

      <h3>Contents</h3>
      { !!contentsList.length && <JobCardList>
        {contentsList}
      </JobCardList> }
      { !contentsList.length && <p>見つかりませんでした。</p>}
    </Article>
  );
}

export default MemberJobs;
