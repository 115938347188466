import React from "react";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";

import { JobBadge } from "./job-icon";


const JOB_MAX_LEVEL = 90;

export const JobCard = ({ job }) => {
  if (!job) { return false; }

  const expPercentage = job.exp && job.exp.next ? Math.round(job.exp.current / job.exp.next * 100) : null;

  return (<div css={JobCardStyle}>
    { job.meister && <span className="job-meister" title="Meister" /> }
    { job.key && <JobBadge className="job-icon" data-job-type={job.type} slug={job.key} /> }
    <span className="job-level" data-job-type={job.type} data-job-level={job.level}>{job.level}</span>
    { expPercentage != null && <span className="job-exp" title={`${job.exp.current} / ${job.exp.next} Exp`}>
      <span className="exp-bar" style={{ width: `${expPercentage}%`}} />
    </span> }
    <span className="job-name">{job.name}</span>
  </div>);
}

const JobCardStyle = css`
  position: relative;
  margin-bottom: 1rem;
  min-width: 5rem;

  .job-meister {
    position: absolute;
    display: block;
    right: 0.5rem;
    content: "";

    width: 8px;
    height: 8px;

    background: #B71C1C;
    border-radius: 4px;
  }

  .job-icon {
    position: absolute;
    top:  0rem;
    left: .5rem;
  }

  .job-exp {
    position: absolute;
    overflow: hidden;
    display: block;
    right: .75rem;
    margin-top: -5px;

    width: 1.75rem;
    height: 4px;
    border-radius: 3px;
    border: 1px solid #fff;

    background: #C9CCCD;

    .exp-bar {
      content: "";
      display: block;
      background: #828485;

      height: 4px;
      border-radius: 2px;
    }
  }

  .job-level {
    display: block;
    margin: 0 auto;
    width: 4rem;
    height: 4rem;

    border-radius: 50%;
    background: #EAEDEE;

    font-weight: bold;
    font-size: 1.75rem;
    line-height: 4rem;
    text-align: center;

    &[data-job-type="tank"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #3F51B5;
      }

      &[data-job-level="0"] {
        background: #E8EAF6;
      }

      background: #7986CB;
      color: #fff;
    }

    &[data-job-type="healer"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #7CB342;
      }

      &[data-job-level="0"] {
        background: #F1F8E9;
      }

      background: #AED581;
      color: #fff;
    }

    &[data-job-type="melee"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #B71C1C;
      }

      &[data-job-level="0"] {
        background: #FFEBEE;
      }

      background: #EF5350;
      color: #fff;
    }

    &[data-job-type="range"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #B71C1C;
      }

      &[data-job-level="0"] {
        background: #FFEBEE;
      }

      background: #EF5350;
      color: #fff;
    }

    &[data-job-type="magic"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #B71C1C;
      }

      &[data-job-level="0"] {
        background: #FFEBEE;
      }

      background: #EF5350;
      color: #fff;
    }

    &[data-job-type="crafter"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #6D6F70;
      }

      &[data-job-level="0"] {
        background: #F0F3F4;
      }

      background: #ACAEAF;
      color: #fff;
    }

    &[data-job-type="gatherer"] {

      &[data-job-level="${JOB_MAX_LEVEL}"] {
        background: #6D6F70;
      }

      &[data-job-level="0"] {
        background: #F0F3F4;
      }

      background: #ACAEAF;
      color: #fff;
    }
  }

  .job-name {
    display: block;
    margin: 0 auto;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;

    text-align: center;
    font-weight: bold;
    font-size: 0.625rem;
  }
`;


export const JobCardList = (props) => {
  return <div css={JobListStyle} {...props}>{props.children}</div>;
}

const JobListStyle = css`
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoints.xs} {
    justify-content: center;
  }
`;
