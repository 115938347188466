import React from "react";
import { DateTime } from "luxon";
import Article from "./article";
import { AchievementList, AchievementCard } from "./achievement-card";

const MemberAchievement = ({ member }) => {

  return(
    <Article>
      <h2>Latest Achievements</h2>
      <AchievementList>
        {member.achievement.latest_achievement.map((node, index) => (
          <AchievementCard key={index} achievement={node} />
        ))}
      </AchievementList>

      { member.achievement.first_achievement &&
        <>
          <h2>First Achievement</h2>
          <p>初アチーブメント取得日： {DateTime.fromISO(member.achievement.first_achievement.date).toFormat('yyyy年MM月dd日')}</p>
        </>
      }
      { !member.achievement.first_achievement && <p>アチーブメントが見つからないか公開されていません。</p> }
    </Article>
  );
}


export default MemberAchievement;
