import React from "react";
import { DateTime } from "luxon";
import { css } from "@emotion/react";

import Article from "./article";
import { NewBadge } from "../components/badge";

const MemberBlog = ({ member }) => {
  const blogArticles = [];
  const now = DateTime.local();

  for (var i in member.blog.articles) {
    let { date, title, url } = member.blog.articles[i];
    let articleDate = DateTime.fromISO(date);
    let isNewest = (articleDate.plus({ days: 3 }) > now);

    blogArticles.push(
      <li key={i} css={blogArticleCardStyle}>
        <a href={url} className="article-pointer" target="_blank" rel="noreferrer">
          <time className="article-date" dateTime={articleDate}>
            { isNewest && <NewBadge top={0} right="-6px" /> }
            {articleDate.toFormat('yyyy-MM-dd')}
          </time>
          <span className="article-title">{title}</span>
        </a>
      </li>
    );
  }

  return (
    <Article>
      <h2>Blog</h2>
      <h3>Latest Blog</h3>
      { blogArticles && <ul>
        {blogArticles}
      </ul> }
      { !blogArticles.length && <p>ブログ記事は見つかりませんでした。</p> }
    </Article>
  );
}

const blogArticleCardStyle = css`
  margin-bottom: 0.5rem;

  .article-pointer {
    color: inherit;
    text-decoration: none;

    &:hover .article-title {
      text-decoration: underline;
    }
  }

  .article-date {
    display: inline-block;
    margin-right: 1rem;

    font-weight: bold;
  }

  .article-title {
    display: inline-block;
  }
`;

export default MemberBlog;
