import React, { useState, useEffect, useCallback } from "react";
import Layout from "../layouts/default";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { Router, Link } from "@reach/router";
import { DateTime } from "luxon";
import queryString from "query-string";

import SEO from "../components/seo";
import CharaFaceImg from "../components/chara-face";
import MemberProfile from "../components/member-profile";
import MemberJobs from "../components/member-jobs";
import MemberBlog from "../components/member-blog";
import MemberAchievement from "../components/member-achievement";
import { JobBadge } from "../components/job-icon";

import Typography from '../styles/typography';
import { Section, Container, breakpoints, gutters } from "../styles/layouts";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/pro-light-svg-icons';

library.add(faAngleRight, faAngleLeft);

const ProfileLink = (props) => {

  const isActive = ({ isCurrent }) => {
    return isCurrent ? { 'data-is-active': true } : { 'data-is-active': false }
  }

  return (
    <Link
      getProps={isActive}
      {...props}
    >{props.children}</Link>
  );
}

export default ({ data, pageContext }) => {
  const [colors, setColors] = useState(null);
  const [bgColor, setBgColor] = useState('#4D4F50');
  const member = data.allMembersJson.edges[0].node;

  const parseFaceImageURL = useCallback(() => {
    let image_url = queryString.parseUrl(`${process.env.GATSBY_PROFILE_IMGIX_URL}/face_image/${member.images.face}`);
    image_url.query.palette = 'json';
    image_url.query.fit = 'crop';
    image_url.query.crop = 'focalpoint';
    image_url.query['fp-x'] = 0.5;
    image_url.query['fp-y'] = 0.5;
    image_url.query['fp-z'] = 2.25;
    image_url.query.w = 64;
    image_url.query.h = 64;

    return image_url;
  }, [member]);

  const parseBodyImageURL = useCallback(() => {
    let image_url = queryString.parseUrl(`${process.env.GATSBY_PROFILE_IMGIX_URL}/body_image/${member.images.body}`);
    image_url.query.w = 1200;
    image_url.query.h = 640;
    image_url.query.q = 100;
    image_url.query.fit = 'fillmax';
    image_url.query.fill = 'blur';
    image_url.query.sharp = 5;
    image_url.query.auto = 'compress,enhance,format';

    return image_url;
  }, [member]);

  const ogpImageURL = useCallback(() => {
    return queryString.stringifyUrl(parseBodyImageURL());
  }, [parseBodyImageURL]);

  useEffect(() => {
    if(!parseFaceImageURL()) return;

    fetch(queryString.stringifyUrl(parseFaceImageURL()))
      .then(response => response.json())
      .then(data => setColors(data));
  }, [parseFaceImageURL]);

  useEffect(() => {
    if(!colors) return;

    if (colors.dominant_colors && colors.dominant_colors.muted_dark) {
      setBgColor(colors.dominant_colors.muted_dark.hex);
    } else if (colors.dominant_colors && colors.dominant_colors.vibrant_dark) {
      setBgColor(colors.dominant_colors.vibrant_dark.hex);
    } else if (colors.dominant_colors && colors.dominant_colors.vibrant) {
      setBgColor(colors.dominant_colors.vibrant.hex);
    }
  }, [colors]);

  return (
    <Layout>
      <SEO title={member.profile.name.full} ogpImage={ogpImageURL} />
      <header css={ProfileHeaderStyle} style={{ background: bgColor }}>
        <Container>
          <div className="profile-face">
            <div className="face-wrapper">
              { member.current_job &&  <JobBadge
                className="profile-current-job"
                data-job-type={member.current_job.type}
                slug={member.current_job.key}
                title={member.current_job.name} /> }
              <CharaFaceImg className="face-image" filename={member.images.face} width={96} height={96} />
            </div>
            <div className="face-names">
              <div className="names-title">
                { member.profile.title && <span>{member.profile.title}</span> }
                { member.profile.title && member.profile.legacy && <span> / </span> }
                { member.profile.legacy && <span>Legacy</span> }
              </div>
              <h1 className="names-full">{ member.profile.name.full }</h1>
              <div className="names-nickname">{member.nickname}</div>
            </div>
          </div>
          <nav className="profile-nav">
            <ul className="nav-list">
              <li className="list-item">
                <ProfileLink to={`/members/${member.slug}`}
                  className="item-pointer"
                >Top</ProfileLink>
              </li>
              <li className="list-item">
                <ProfileLink to={`/members/${member.slug}/jobs`}
                  className="item-pointer"
                >Job</ProfileLink>
              </li>
              <li className="list-item">
                <ProfileLink to={`/members/${member.slug}/achievement`}
                  className="item-pointer"
                >Achieve</ProfileLink>
              </li>
              <li className="list-item">
                <ProfileLink to={`/members/${member.slug}/blog`}
                  className="item-pointer"
                >Blog</ProfileLink>
              </li>
              {/* <li className="list-item">
                <a href={member.lodestone_url}
                  className="item-pointer"
                  target="_blank"
                  rel="nofollow"
                >Lodestone</a>
              </li> */}
            </ul>
          </nav>
          { pageContext.prev && <div className="member-nav-prev">
            <Link to={`/members/${pageContext.prev}`} className="nav-pointer">
              <FontAwesomeIcon className="icon" icon={["fal", "angle-left"]} title={data.prevMember.profile.name.full} />
            </Link>
          </div> }
          { pageContext.next && <div className="member-nav-next">
            <Link to={`/members/${pageContext.next}`} className="nav-pointer">
              <FontAwesomeIcon className="icon" icon={["fal", "angle-right"]} title={data.nextMember.profile.name.full} />
            </Link>
          </div> }
        </Container>
      </header>
      <main>
        <Section>
          <Container>
            <p css={UpdateDateStyle}>Update: {DateTime.fromISO(member.updateAt).toFormat('yyyy-MM-dd')}</p>
            <Router basepath={`/members/${member.slug}`}>
              <MemberProfile member={member} path={`/`} />
              <MemberJobs member={member} path={`/jobs`} />
              <MemberBlog member={member} path={`/blog`} />
              <MemberAchievement member={member} path={`/achievement`} />
            </Router>
            <nav css={CharactorNavStyle}>
              <ul className="nav-list">
                { pageContext.prev && <li className="nav-prev">
                  <Link to={`/members/${pageContext.prev}`} className="nav-pointer">
                    <CharaFaceImg className="nav-icon" filename={data.prevMember.images.face} width={32} height={32} />
                    {data.prevMember.profile.name.full}
                  </Link>
                </li> }
                { pageContext.next && <li className="nav-next">
                  <Link to={`/members/${pageContext.next}`} className="nav-pointer">
                    {data.nextMember.profile.name.full}
                    <CharaFaceImg className="nav-icon" filename={data.nextMember.images.face} width={32} height={32} />
                  </Link>
                </li> }
              </ul>
            </nav>
          </Container>
        </Section>
      </main>
    </Layout>
  )
}

const CharactorNavStyle = css`
  margin-top: 5rem;

  .nav-list {
    list-style: none;
    margin: 0;
    padding: 0;

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }

  .nav-pointer {
    color: #504F4C;
  }

  .nav-prev {
    float: left;

    .nav-icon {
      display: inline-block;
      margin-right: 1rem;
      vertical-align: middle;
    }
  }

  .nav-next {
    float: right;

    .nav-icon {
      display: inline-block;
      margin-left: 1rem;

      vertical-align: middle;
    }
  }
`;

const UpdateDateStyle = css`
  margin: -2rem 0 0 0;
  margin-block-end: 0;
  padding: 0;
  text-align: right;
  font-size: .75rem;
  color: #ACAEAF;
`;

const ProfileHeaderStyle = css`
  position: relative;
  padding-top: calc(7rem + ${gutters.console.row});

  background: #4D4F50;
  /* background: linear-gradient(125deg, rgba(52,27,21,1) 0%, rgba(80,49,37,1) 100%); */

  @media ${breakpoints.handheld} {
    padding-top: calc(5rem + ${gutters.handheld.row});
  }

  .profile-face {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media ${breakpoints.handheld} {
      flex-direction: column;
    }
  }

  .face-wrapper {
    position: relative;
    margin-right: calc(${gutters.console.col} / 2);

    @media ${breakpoints.handheld} {
      margin-right: 0;
      margin-bottom: ${gutters.handheld.row};
    }
  }

  .face-image {
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.25);
    }
  }

  .profile-current-job {
    position: absolute;
    top: -.25rem;
    left: -.25rem;
  }

  .face-names {

  }

  .names-title {
    margin: 0;
    min-height: 1.5em;
    ${Typography.h6};
    font-weight: bold;
    color: #F6486C;
  }

  .names-full {
    margin: 0;
    ${Typography.h1};
    color: #fff;
    text-align: left;
  }

  .names-nickname {
    margin: 1rem 0 0 0;
    color: #fff;
  }

  .profile-nav {
    margin-top: ${gutters.console.row};

    @media ${breakpoints.handheld} {
      margin-top: ${gutters.handheld.row};
    }

    .nav-list {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;

      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: col;

      @media ${breakpoints.handheld} {
        justify-content: center;
      }

      .list-item {
        list-style: none;
      }

      .item-pointer {
        display: block;
        padding: .75rem 2rem;
        border-radius: 3px 3px 0 0;
        transition: background .2s ease-in-out;

        @media ${breakpoints.handheld} {
          padding: .5rem 1rem;
        }

        color: #F5F8F9;
        letter-spacing: 0.1em;
        text-indent: 0.1em;

        &:hover {
          text-decoration: none;
          background-color: rgba(255,255,255,.1);
        }

        &[data-is-active="true"] {
          background-color: #FAFDFE;
          color: #2B2D2E;
        }
      }
    }
  }

  .member-nav-prev {
    position: absolute;
    top: 5rem;
    bottom: 0;
    left: 0;
    margin: auto 0;
    height: 4rem;

    list-style: none;

    @media ${breakpoints.lg} {
      left: 2rem;
    }
    @media ${breakpoints.xl} {
      left: 2rem;
    }

    .nav-pointer {
      transition:
        transform .2s ease-in-out,
        color .2s ease-in-out;
      display: block;
      padding: 1rem;
      height: 2rem;

      font-size: 2rem;
      color: rgba(255,255,255,.25);

      &:hover {
        transform: scale(1.15);
        color: rgba(255,255,255,1);
      }

      &:active {
        transform: scale(1);
        color: rgba(255,255,255,1);
      }
    }
  }

  .member-nav-next {
    position: absolute;
    top: 5rem;
    bottom: 0;
    right: 0;

    margin: auto 0;
    height: 4rem;

    @media ${breakpoints.lg} {
      right: 2rem;
    }
    @media ${breakpoints.xl} {
      right: 2rem;
    }

    .nav-pointer {
      transition:
        transform .2s ease-in-out,
        color .2s ease-in-out;
      display: block;
      padding: 1rem;
      height: 2rem;

      font-size: 2rem;
      color: rgba(255,255,255,.25);

      &:hover {
        transform: scale(1.15);
        color: rgba(255,255,255,1);
      }

      &:active {
        transform: scale(1);
        color: rgba(255,255,255,1);
      }
    }
  }
`;

export const query = graphql`
  query($slug: String!, $prev: String, $next: String) {
    prevMember: membersJson(slug: {eq: $prev}) {
      images {
        face
      }
      profile {
        name {
          first
          full
          last
        }
      }
    }
    nextMember: membersJson(slug: {eq: $next}) {
      images {
        face
      }
      profile {
        name {
          first
          full
          last
        }
      }
    }
    allMembersJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          images {
            face
            body
          }
          profile {
            name {
              first
              full
              last
            }
            title
            self_introduction
            current_job_name
            birthday {
              full
              month
              day
            }
            city_state
            race {
              name
              gender
              clan
            }
            guardian
            grand_company {
              name
              rank
            }
            legacy
          }
          nickname
          lodestone_url
          achievement {
            point
            latest_achievement {
              title
              url
              date
            }
            first_achievement {
              title
              date
            }
            legacy_achievement {
              title
              date
            }
          }
          minion {
            minion_count
          }
          mount {
            mount_count
          }
          current_job {
            key
            level
            max_level
            name
            type
            exp {
              current
              next
            }
          }
          jobs {
            battle {
              key
              level
              max_level
              name
              type
              exp {
                current
                next
              }
            }
            crafter {
              key
              level
              max_level
              name
              type
              meister
              exp {
                current
                next
              }
            }
            gatherer {
              key
              level
              max_level
              name
              type
              exp {
                current
                next
              }
            }
          }
          contents {
            levels {
              level
              name
              type
              exp {
                current
                next
              }
            }
          }
          blog {
            articles {
              title
              url
              date
            }
          }
          lodestone {
            achievement
            blog
            jobs
            legacyAchievement
            minion
            mount
            profile
          }
          role
          room
          join_date
          slug
          updateAt
        }
      }
    }
  }
`;
