import React from "react";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";

export const PointList = (props) => {
  return (
    <div css={PointListStyle}>
      {props.children}
    </div>
  );
}

const PointListStyle = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
  margin-bottom: 1rem;

  @media ${breakpoints.xs} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const PointCard = ({ title, point, to }) => {

  if (to) {
    return (
      <a href={to} target="_blank" rel="noreferrer" css={PointCardStyle}>
        <h3 className="card-title">{title}</h3>
        <div className="card-point">{point}</div>
      </a>
    );
  }

  return (
    <div css={PointCardStyle}>
      <h3 className="card-title">{title}</h3>
      <div className="card-point">{point}</div>
    </div>
  );
}

const PointCardStyle = css`
  transition: box-shadow .2s ease-in-out;
  padding: 1.5rem;
  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.24), 0px 1px 4px -1px rgba(0, 0, 0, 0.12), 0px 1px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;

  color: #504F4C;
  text-decoration: none;

  &:hover {
    box-shadow: 0px 16px 16px -16px rgba(0, 0, 0, 0.24), 0px 4px 32px -8px rgba(0, 0, 0, 0.12), 0px 6px 48px -12px rgba(0, 0, 0, 0.06);
    text-decoration: none;
  }

  .card-title {
    margin-block-start: 0;
    margin-block-end: 0.75rem;

    font-weight: bold;
    font-size: 1rem;
    color: #ACAEAF;
  }

  .card-point {
    font-size: 2rem;
    line-height: 1.25;
    font-weight: bold;
  }
`;
