import React from "react";
import styled from "@emotion/styled";

import { breakpoints } from "../styles/layouts";
import Typography from '../styles/typography';

export default ({ background, children }) => {
  return (
    <Article background={background}>
      {children}
    </Article>
  );
}

const Article = styled.article`

  & > *:first-child {
    margin-block-start: 0;
  }

  & > *:last-child {
    margin-block-end: 0;
  }

  h1 {
    margin-block-start: 2em;
    ${Typography.h1};
    color: #4D4F50;
  }

  h2 {
    position: relative;
    margin-block-start: 2em;
    margin-block-end: 1em;
    padding-block-end: .75rem;
    ${Typography.h2};
    letter-spacing: .05em;
    color: #4D4F50;

    border-bottom: 1px solid #C9CCCD;

    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -1px;

      width: 25%;
      height: 1px;

      background-color: #B81D4C;
    }
  }

  h3 {
    margin-block-start: 1.75em;
    ${Typography.h3};
    font-weight: normal;
    letter-spacing: .05em;
    color: #1D1E1F;
  }

  h4 {
    margin-block-start: 1.75em;
    ${Typography.h4};
    font-weight: normal;
    letter-spacing: .05em;
    color: #1D1E1F;
  }

  h5 {
    margin-block-start: 1.75em;
    ${Typography.h5};
    color: #1D1E1F;
  }

  p {
    ${Typography.p};
  }

  ul, ol {
    margin-block-start: 1rem;

    li {
      ${Typography.p};
      line-height: 1.75;
    }
  }

  figure {
    margin: 1em 0;
  }

  table {
    margin-block-start: 1rem;
    width: 100%;

    border-radius: 6px;
    box-shadow: 0 1px 4px rgba(0,0,0,.1);

    tr {
      th, td {
        border-bottom: 1px solid #EAEDEE;
      }

      &:last-child {
        th, td {
          border-bottom: none;
        }
      }
    }

    th {
      background: #F0F3F4;
    }

    th, td {
      padding: 1rem;
      ${Typography.p};

      @media ${breakpoints.handheld} {
        padding: .5rem;
      }
    }
  }
`;
