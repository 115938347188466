import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { css } from "@emotion/react";

import { breakpoints } from "../styles/layouts";
import Article from "./article";
import { PointCard, PointList } from "../components/point-card";
import { AchievementList, AchievementCard } from "./achievement-card";
import CharaBodyImg from "./chara-body";
import { JobCard, JobCardList } from "./job-card";

const MemberProfile = ({ member }) => {
  const [meister, setMeister] = useState([]);

  useEffect(() => {
    setMeister(member.jobs.crafter.filter(job => job.meister));
  }, [member]);

  const meisterJobList = () => {
    return meister.map((job, index) => {
      return <JobCard key={index} job={job} />;
    });
  }

  return(
    <Article>
      <h2>Sumally</h2>
      <PointList>
        <PointCard title="Achievement" point={member.achievement.point || '-'} to={member.lodestone.achievement} />
        <PointCard title="Mount" point={member.mount.mount_count} to={member.lodestone.mount} />
        <PointCard title="Minion" point={member.minion.minion_count} to={member.lodestone.minion} />
      </PointList>

      { member.achievement.latest_achievement.length !== 0 && <>
        <h3>Latest Achievement</h3>
        <AchievementList>
          {member.achievement.latest_achievement.slice(0,3).map((node, index) => (
            <AchievementCard key={index} achievement={node} />
          ))}
        </AchievementList>
      </> }

      { (!!meister.length) && <>
        <h3>Meister Job</h3>
        <JobCardList>
          {meisterJobList()}
        </JobCardList>
      </> }

      <h3>Current Job</h3>
      <JobCardList>
        <JobCard job={member.current_job} />
      </JobCardList>

      <h2>Profile</h2>
      <div css={ProfileGridStyle}>
        <div className="profile-image">
          <CharaBodyImg filename={member.images.body} />
        </div>

        <div className="profile-contents">
          <h3>種族</h3>
          <p>{member.profile.race.name} {member.profile.race.gender} / {member.profile.race.clan}</p>

          <h3>誕生日</h3>
          <ul>
            <li>エオルゼア歴: {member.profile.birthday.full}</li>
            <li>西暦: {member.profile.birthday.month}月{member.profile.birthday.day}日</li>
          </ul>

          <h3>守護神</h3>
          <p><GuardianBadge month={member.profile.birthday.month} /> {member.profile.guardian}</p>

          <h3>グランドカンパニー</h3>
          <p>{member.profile.grand_company.name} / {member.profile.grand_company.rank}</p>

          <h3>開始国</h3>
          <p>{member.profile.city_state}</p>
        </div>
      </div>

      <h2>FreeCompany</h2>
      <h3>加入日</h3>
      <ul>
        <li>{DateTime.fromISO(member.join_date).toFormat('yyyy年MM月dd日')}</li>
        <li>経過日数： {
          Math.floor(Math.abs(DateTime.fromISO(member.join_date).diffNow('days').toObject().days)).toLocaleString()
        }日</li>
      </ul>

      <h3>個室</h3>
      <p>{ !!member.room && member.room.toString().padStart(3, "0") || '個室を持っていません。' }</p>

      <h3>ロール</h3>
      <p>{member.role}</p>
    </Article>
  );
}

const ProfileGridStyle = css`

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  .profile-image {
    overflow: hidden;
    float: right;
    margin-left: 2rem;
    width: 480px;

    @media ${breakpoints.handheld} {
      float: none;
      margin: 0 auto;
      width: 100%;
      max-width: 480px;
    }
  }

  .profile-contents {

  }
`;

const GuardianBadge = ({ month }) => {

  return (
    <span css={GuardianBadgeStyle} data-is-month={month} />
  );
}

const GuardianBadgeStyle = css`
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: .5em;

  vertical-align: -0.125em;
  background: #eee;

  &[data-is-month="1"] {
    background: #2196F3;
  }

  &[data-is-month="2"] {
    background: #2196F3;
  }

  &[data-is-month="3"] {
    background: #00BCD4;
  }

  &[data-is-month="4"] {
    background: #00BCD4;
  }

  &[data-is-month="5"] {
    background: #4CAF50;
  }

  &[data-is-month="6"] {
    background: #4CAF50;
  }

  &[data-is-month="7"] {
    background: #673AB7;
  }

  &[data-is-month="8"] {
    background: #673AB7;
  }

  &[data-is-month="9"] {
    background: #F44336;
  }

  &[data-is-month="10"] {
    background: #F44336;
  }

  &[data-is-month="11"] {
    background: #FF9800;
  }

  &[data-is-month="12"] {
    background: #FF9800;
  }
`;


export default MemberProfile;
