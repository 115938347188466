import React from "react";
import { css } from "@emotion/react";
import { DateTime } from "luxon";
import { Link } from "gatsby";
import { breakpoints } from "../styles/layouts";

import CharaFaceImg from "../components/chara-face";
import { NewBadge } from "../components/badge";

export const AchievementCard = ({ achievement }) => {
  const now = DateTime.local();
  const achievementDate = DateTime.fromISO(achievement.date);
  const isNewest = (achievementDate.plus({ days: 3 }) > now);

  return (
    <li css={AchievementCardStyle}>
      { achievement.profile &&
        <Link
          to={`/members/${achievement.profile.slug}`}
          title={achievement.profile.profile.name.full}
          className="achievement-chara-image">
          <CharaFaceImg filename={achievement.profile.images.face} width={24} height={24} />
        </Link>
      }
      <a href={achievement.url} className="achievement-pointer" target="_blank" rel="noreferrer">
        <time dateTime={achievement.date} className="achievement-date">
          { isNewest && <NewBadge top={0} right="-6px" /> }
          {DateTime.fromISO(achievement.date).toFormat('yyyy-MM-dd')}
        </time>
        <span className="achievement-title">{achievement.title}</span>
      </a>
    </li>
  );
}

export const AchievementList = (props) => {
  return (
    <ul css={ActivityListStyle} {...props}>
      {props.children}
    </ul>
  );
}

const AchievementCardStyle = css`
  position: relative;
  margin-bottom: 1.25rem;

  .achievement-chara-image {
    transition: transform .1s ease-in-out;
    display: inline-block;
    margin-right: 1rem;

    vertical-align: -.325rem;

    &:hover {
      transform: scale(1.5);
    }

    &:active {
      transform: scale(1.25);
    }
  }

  .achievement-pointer {
    color: inherit;
    text-decoration: none;

    &:hover .achievement-title {
      text-decoration: underline;
    }
  }

  .achievement-date {
    position: relative;
    display: inline-block;
    margin-right: 1rem;

    font-weight: bold;
  }

  .achievement-title {
    display: inline-block;
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 0;

    @media ${breakpoints.xs} {
      margin-block-start: .5rem;
    }
  }
`;

const ActivityListStyle = css`
  padding-inline-start: 0;
  list-style: none;
`;
